










import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class UlBreadcrumbs extends Vue {
  @Prop() items!: [];
}
