


















import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class UIDialogDelete extends Vue {
  @Prop() value!: boolean;
  @Prop() deletingName!: string;
  @Prop() deleteCallback!: () => void;

  @Emit()
  public input(value: boolean) {}

  private get localValue(): boolean {
    return this.value;
  }

  private set localValue(value: boolean) {
    this.input(value);
  }
}
